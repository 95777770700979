import { useEffect, useRef, useState } from "react";
import "./App.css";
import { CodeSection } from "./components/botMessageSection/codesection";
import {
  ArrowCircleRight,
  FolderIcon,
  QuestionPlaceholder,
  RefreshIcon,
  UnbanxLogo,
} from "./components/icons/icons";
import {
  ChatThread,
  QuestionRecommendation,
  getQuestionRecommendations,
  newDataChat,
  postChatMessage,
} from "./req";

function App() {
  const [chatId, setChatId] = useState<number | null>(null);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [chatThread, setChatThread] = useState<ChatThread>([]);
  const [questionRecommendations, setQustionRecommendations] = useState<
    QuestionRecommendation[]
  >([{ question: "" }, { question: "" }, { question: "" }]);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [dataChatMode, setDataChatMode] = useState(false);
  const [showRecLoadingPlaceholder, setShowRecLoadingPlaceholder] =
    useState(true);
  const loadingSpinnerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (showLoadingSpinner && loadingSpinnerRef.current) {
      loadingSpinnerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [loadingSpinnerRef, showLoadingSpinner]);

  useEffect(() => {
    const initializeChat = async () => {
      let cidOrErr = await newDataChat();
      if ("result" in cidOrErr) {
        setChatId(cidOrErr.result);
      }
    };
    initializeChat();
  }, []);

  useEffect(() => {
    const handleQuestionRecommendations = async () => {
      let response = await getQuestionRecommendations();
      setQustionRecommendations(response);
      setShowRecLoadingPlaceholder(false);
    };
    handleQuestionRecommendations();
  }, []);

  useEffect(() => {
    if (chatThread.length > 0) {
      setDataChatMode(true);
    }
  }, [chatThread]);

  const handlePostChatMessage = async (message: string) => {
    setSearchInputValue(message);
    if (message.length > 2 && chatId) {
      setShowLoadingSpinner(true);
      setDataChatMode(true);
      let newThread = chatThread;
      newThread = [...newThread, { tag: "UserMessage", contents: message }];
      setChatThread(newThread);
      const botResponse = await postChatMessage(chatId, message);
      newThread = [
        ...newThread,
        { tag: "BotMessage", contents: botResponse.newMessages },
      ];

      setChatThread(newThread);
      setShowLoadingSpinner(false);
      setSearchInputValue("");
    }
  };

  // every time some event happens
  // make an async call and then push to the end of chartOutputs

  /**
   * {
   *
   * }
   */

  return (
    <div className="app-container">
      <div className="side-menu">
        <div className="logo">
          <UnbanxLogo />
        </div>
        <div className="divider"></div>
        <div className="menu-item-list">
          <div className="menu-item selected">
            <FolderIcon className="menu-item-icon" />
            <span>Chat</span>
          </div>
        </div>
      </div>
      <div
        className={
          "data-chat-container " + (dataChatMode ? "data-chat-mode" : "")
        }
      >
        {dataChatMode && (
          <div className="header-bar">
            <h1>Data Chat</h1>
            <button
              className="normal-button"
              onClick={() => window.location.reload()}
            >
              <RefreshIcon />
              Reset
            </button>
          </div>
        )}
        {!dataChatMode && <h1 className="page-title">Ask our Data Chat</h1>}
        {dataChatMode && (
          <>
            {chatThread.map((message, index) => {
              if (message.tag === "UserMessage") {
                return (
                  <div key={index} className="user-message">
                    {message.contents}
                  </div>
                );
              } else if (message.tag === "BotMessage") {
                return message.contents.map((section, i) => {
                  if (section.tag === "TextSection") {
                    return (
                      <div
                        key={`${index}-${i}`}
                        className="bot-message-section"
                      >
                        {section.contents}
                      </div>
                    );
                  } else if (section.tag === "InlineCodeSection") {
                    return (
                      <div
                        key={`${index}-${i}`}
                        className="bot-message-section inline-code-section"
                      >
                        {section.contents}
                      </div>
                    );
                  } else if (section.tag === "SuggestionsSection") {
                    return (
                      <div
                        key={`${index}-${i}`}
                        className="bot-message-section suggestion-section"
                      >
                        {section.contents.map((message, i) => (
                          <button
                            key={`"suggestion-button"-${i}`}
                            className="suggestion-button"
                            onClick={async (e) => {
                              e.preventDefault();
                              handlePostChatMessage(message);
                            }}
                          >
                            {message}
                          </button>
                        ))}
                      </div>
                    );
                  } else if (section.tag === "CodeSection") {
                    return (
                      <CodeSection
                        sectionContent={section.contents}
                        chatIndex={index}
                        sectionIndex={i}
                        handlePostChatMessage={handlePostChatMessage}
                      />
                    );
                  }
                });
              } else {
                return null;
              }
            })}
            {showLoadingSpinner && (
              <div className="loading-animation" ref={loadingSpinnerRef}>
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            )}
          </>
        )}
        {!dataChatMode && (
          <div className="recommendations-container">
            {questionRecommendations.map((rec, i) => (
              <div
                onClick={() => handlePostChatMessage(rec.question)}
                key={"rec-" + i}
                className="recommendation-card"
              >
                {!showRecLoadingPlaceholder && (
                  <span className="rec-text">{rec.question}</span>
                )}
                {showRecLoadingPlaceholder && <QuestionPlaceholder />}
                <ArrowCircleRight className="arrow-icon" />
              </div>
            ))}
          </div>
        )}
        <div
          className={
            "search-backdrop " + (dataChatMode ? "data-chat-mode" : "")
          }
        >
          <div className="main-search-bar-container">
            <textarea
              className="main-search-bar"
              wrap="off"
              maxLength={200}
              placeholder="Type your message here..."
              value={searchInputValue}
              onChange={(e) => {
                setSearchInputValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handlePostChatMessage(searchInputValue);
                }
              }}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
