import { useEffect, useState } from "react";
import type { ChartConfig } from "../../req";
import { runCodeAndGenChart } from "../../req";
import { ChartComponent, OutputVals } from "../chart/chart";
import { DownChevronIcon } from "../icons/icons";
import { DataTable } from "../table/table";

export const CodeSection = ({
  sectionContent,
  chatIndex,
  sectionIndex,
  handlePostChatMessage,
}: {
  sectionContent: string;
  chatIndex: number;
  sectionIndex: number;
  handlePostChatMessage: (message: string) => Promise<void>;
}) => {
  const [showChartLoadingSpinner, setShowChartLoadingSpinner] = useState(false);
  const [chartElement, setChartElement] = useState<JSX.Element>(<></>);
  const [hideChartSection, setHideChartSection] = useState(false);
  const [outputVals, setOutputVals] = useState<OutputVals>();
  const [showCode, setShowCode] = useState(false);

  const handleRunCodeAndGetChart = async (
    sectionContent: string
  ): Promise<JSX.Element> => {
    let res = await runCodeAndGenChart(sectionContent);
    console.log(res);
    let outputVals: OutputVals = {
      colNames: [],
      resultVals: [],
    };
    if ("result" in res.sqlVals) {
      outputVals.colNames = res.sqlVals.result.colNames;
      outputVals.resultVals = res.sqlVals.result.resultVals;
      setOutputVals(outputVals);
    }
    if ("result" in res.sqlVals && res.sqlVals.result.colNames.length <= 1) {
      setHideChartSection(true);
    }
    if ("error" in res.sqlVals) {
      handlePostChatMessage("fix the following error: " + res.sqlVals.error);
      return <div className="bot-message-section">{res.sqlVals.error}</div>;
    } else if (
      "result" in res.sqlVals &&
      res.sqlVals.result.colNames.length > 1
    ) {
      let chartConfig: null | ChartConfig = null;
      if ("result" in res.chartConfig) chartConfig = res.chartConfig.result;
      return (
        <ChartComponent outputVals={outputVals} chartConfig={chartConfig} />
      );
    } else {
      return <></>;
    }
  };
  useEffect(() => {
    setShowChartLoadingSpinner(true);
    (async () => {
      var chart = <></>;
      chart = await handleRunCodeAndGetChart(sectionContent);
      setChartElement(chart);
      setShowChartLoadingSpinner(false);
    })();
  }, [sectionContent]);

  const toggleCodeVisibility = () => {
    setShowCode((prevShowCode) => !prevShowCode);
  };

  return (
    <>
      <div
        key={`${chatIndex}-${sectionIndex}`}
        className={
          "bot-message-section code-section " + (showCode ? "open" : "")
        }
      >
        {showCode ? <code>{sectionContent}</code> : "View SQL"}
        <div className="code-section-icon" onClick={toggleCodeVisibility}>
          <DownChevronIcon />
        </div>
      </div>

      {showChartLoadingSpinner && (
        <div className="loading-animation">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      )}

      {!showChartLoadingSpinner && !hideChartSection && (
        <div
          className="bot-message-section data-vis-card"
          key={"chart- " + chatIndex + "-" + sectionIndex}
        >
          {chartElement}
        </div>
      )}
      {!showChartLoadingSpinner && outputVals && (
        <DataTable outputVals={outputVals} />
      )}
    </>
  );
};
