const API_ENDPOINT = process.env.REACT_APP_ENDPOINT ? process.env.REACT_APP_ENDPOINT : 'http://localhost:3005'

export type OutputVals = {
  colNames: Array<string>;
  resultVals: Array<Array<string>>;
};

export type ChartConfig = {
  chartType: "bar" | "line";
  labelAxis: string;
  dataAxis: string;
  secondaryAxis: string | null;
};

export type ResultOrErr<T> = { result: T } | { error: string };

export type WithId<T> = { innerId: number; innerVal: T };

export function getHeaders() {
  return {
    // Auth removed. Normally this fills in the Authorization header with the JWT
  };
}

export type RunCodeAndGenChartOutput = {
  chartConfig : ResultOrErr<ChartConfig>;
  sqlVals : ResultOrErr<OutputVals>;
}

export async function runCodeAndGenChart(code: string): Promise<RunCodeAndGenChartOutput> {
  let body = {
    sqlCode: code,
  };
  let res = await fetch(API_ENDPOINT + "/api/code/run_and_gen_chart", {
    headers: getHeaders(),
    method: "POST",
    body: JSON.stringify(body),
  });
  let resBody = await res.json();
  return resBody;
}

export async function runCode(code: string): Promise<ResultOrErr<OutputVals>> {
  let body = {
    sqlCode: code,
  };

  let res = await fetch(API_ENDPOINT + "/api/code/run", {
    headers: getHeaders(),
    method: "POST",
    body: JSON.stringify(body),
  });
  let resBody = await res.json();
  return resBody;
}

// export async function createSheetFromOutputVals(
//   vals: ResultOrErr<OutputVals>
// ): Promise<ResultOrErr<GSheet>> {
//   let body = vals;
//   let res = await fetch(VITE_API + "/api/code/create_sheet", {
//     headers: getHeaders(),
//     method: "POST",
//     body: JSON.stringify(body),
//   });
//   let resBody = await res.json();
//   return resBody;
// }

export async function genChartFromCodeAndCols(
  code: string,
  cols: string[]
): Promise<ResultOrErr<ChartConfig>> {
  let body = {
    sqlCode: code,
    columnNames: cols,
  };
  try {
    let res = await fetch(API_ENDPOINT + "/api/code/gen_chart", {
      headers: getHeaders(),
      method: "POST",
      body: JSON.stringify(body),
    });
    let resBody = await res.json();
    return resBody;
  } catch (error) {
    return { error: JSON.stringify(error) };
  }
}

export async function createSnowflakeConnector(
  input: SnowflakeConnector
): Promise<ResultOrErr<number>> {
  try {
    let res = await fetch(API_ENDPOINT + "/api/connectors/snowflake/", {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(input),
    });
    if (!res.ok) {
      // TODO: do something
    }
    let json = await res.json();
    return json.result;
  } catch (error) {
    return { error: JSON.stringify(error) };
  }
}
export type SnowflakeConnector = {
  name: string;
  deploymentUrl: string;
  clientId: string;
  clientSecret: string;
  accountLocator: string;
  database: string;
  schema: string;
};

export type RedshiftDeployment = {
  redshiftDeploymentHost: string;
  redshiftDeploymentPort: number;
  redshiftDeploymentUser: string;
  redshiftDeploymentPassword: string;
  redshiftDeploymentDatabase: string;
  redshiftDeploymentSchema: string;
};

export type ConnectorsList = [ConnectorListMetadata];
export type ConnectorListMetadata = {
  connectorId: string;
  connectorName: string;
  connectorType: string;
};

export async function getAllConnectors(): Promise<ConnectorsList> {
  try {
    let res = await fetch(API_ENDPOINT + "/api/connectors/", {
      method: "GET",
      headers: getHeaders(),
    });
    if (!res.ok) {
      // TODO: do something
    }
    let json = await res.json();
    return json;
  } catch (error) {
    throw { error: JSON.stringify(error) };
  }
}

export type ConnectorMetadata = {
  name: string;
  snowflakeMetadata?: SnowflakeConnector | null;
  redshiftMetadata?: RedshiftDeployment | null;
};

export async function getConnector(cid: string): Promise<ConnectorMetadata> {
  try {
    let res = await fetch(API_ENDPOINT + "/api/connectors/" + cid, {
      method: "GET",
      headers: getHeaders(),
    });
    if (!res.ok) {
      // TODO: do something
    }
    let json = await res.json();
    return json;
  } catch (error) {
    throw { error: JSON.stringify(error) };
  }
}

export type BotMode = "Disambiguate" | "GenerateSql" | "Failed";

export type BotMessageSection =
  | {
      tag: "CodeSection" | "TextSection" | "InlineCodeSection";
      contents: string;
    }
  | { tag: "SuggestionsSection"; contents: string[] };

export type UserMessage = {
  tag: "UserMessage";
  contents: string;
};

export type Message =
  | UserMessage
  | { tag: "BotMessage"; contents: BotMessageSection[] };

export type ChatThread = Message[];

type BotResponse = {
  newMessages: BotMessageSection[];
  newBotMode: BotMode;
};

export async function postChatMessage(
  chatId: number,
  message: string
): Promise<BotResponse> {
  try {
    let res = await fetch(API_ENDPOINT + "/api/chat/", {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ message: message, chatId: chatId }),
    });
    if (!res.ok) {
      // TODO: do something
    }
    let json = await res.json();
    return json;
  } catch (error) {
    throw { error: JSON.stringify(error) };
  }
}

export async function updateConnector(
  cid: string,
  updateBody: ConnectorMetadata
): Promise<ConnectorMetadata> {
  try {
    let res = await fetch(API_ENDPOINT + "/api/connectors/" + cid + "/update", {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(updateBody),
    });
    if (!res.ok) {
      // TODO: do something
    }
    let json = await res.json();
    return json;
  } catch (error) {
    throw { error: JSON.stringify(error) };
  }
}

export async function insertConnector(
  insertBody: ConnectorMetadata
): Promise<ResultOrErr<number>> {
  try {
    let res = await fetch(API_ENDPOINT + "/api/connectors/new", {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(insertBody),
    });
    if (!res.ok) {
    }
    let json = await res.json();
    return json;
  } catch (error) {
    throw { error: JSON.stringify(error) };
  }
}

export async function getDefaultConnector(): Promise<ResultOrErr<number>> {
  let res = await fetch(API_ENDPOINT + "/api/connectors/default", {
    method: "GET",
    headers: getHeaders(),
  });
  return await res.json();
}

export async function setDefaultConnector(
  cid: string
): Promise<ResultOrErr<number>> {
  let res = await fetch(API_ENDPOINT + "/api/connectors/default/" + cid, {
    method: "POST",
    headers: getHeaders(),
  });
  return await res.json();
}

export type ConnectorsData = {
  connectors: ConnectorsList;
  defaultConnector: ResultOrErr<number>;
};

export async function cloneConnector(
  cid: string
): Promise<ResultOrErr<number>> {
  let res = await fetch(API_ENDPOINT + "/api/connectors/clone/" + cid, {
    method: "POST",
    headers: getHeaders(),
  });
  return await res.json();
}

export type GSheet = {
  spreadsheetId: string;
  spreadsheetUrl: string;
};

export async function createSheet(rid: string): Promise<ResultOrErr<GSheet>> {
  let res = await fetch(API_ENDPOINT + "/api/report/" + rid + "/sheet", {
    method: "POST",
    headers: getHeaders(),
  });
  return await res.json();
}

interface TableCatalogAsset {
  name: string;
  columns: ColumnCatalogAsset[];
  assetId: number;
}

interface ColumnCatalogAsset {
  name: string;
  assetId: number;
  assetParentId: number;
}

export async function getCatalogForConnector(
  cid: string
): Promise<TableCatalogAsset[]> {
  let res = await fetch(API_ENDPOINT + "/api/catalog/" + cid, {
    headers: getHeaders(),
    method: "GET",
  });
  return await res.json();
}

interface CatalogAsset {
  name: string;
  connectorId: number;
  assetType: "ColumnAsset" | "TableAsset";
  assetParentId: number | null;
  assetColumnType: string | null;
  definition: string;
}

export async function getAssetForConnector(
  cid: string,
  aid: string
): Promise<CatalogAsset> {
  let res = await fetch(API_ENDPOINT + "/api/catalog/" + cid + "/" + aid, {
    headers: getHeaders(),
    method: "GET",
  });
  return await res.json();
}

export async function postAssetForConnector(
  cid: string,
  aid: string,
  asset: CatalogAsset
): Promise<ResultOrErr<number>> {
  let res = await fetch(API_ENDPOINT + "/api/catalog/" + cid + "/" + aid, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(asset),
  });
  return await res.json();
}

export async function generateDescriptionForAsset(
  cid: string,
  aid: string
): Promise<ResultOrErr<string>> {
  let res = await fetch(
    API_ENDPOINT + "/api/catalog/" + cid + "/" + aid + "/generate",
    {
      headers: getHeaders(),
      method: "POST",
    }
  );
  return await res.json();
}

export async function getChildAssetsForConnector(
  cid: string,
  aid: string
): Promise<WithId<CatalogAsset>[]> {
  let res = await fetch(
    API_ENDPOINT + "/api/catalog/" + cid + "/" + aid + "/children",
    {
      headers: getHeaders(),
      method: "GET",
    }
  );
  return await res.json();
}

export async function populateCatalogForConnector(
  cid: string
): Promise<ResultOrErr<number>> {
  let res = await fetch(API_ENDPOINT + "/api/catalog/" + cid + "/new", {
    headers: getHeaders(),
    method: "POST",
  });
  return await res.json();
}

export async function newDataChat(): Promise<ResultOrErr<number>> {
  let res = await fetch(API_ENDPOINT + "/api/chat/new", {
    headers: getHeaders(),
    method: "POST",
  });
  return await res.json();
}

export async function getChatThread(chatId: string): Promise<ChatThread> {
  let res = await fetch(API_ENDPOINT + "/api/chat/" + chatId, {
    headers: getHeaders(),
    method: "GET",
  });
  if (!res.ok) {
    throw { error: "Could not get chat thread" };
  }
  let json = await res.json();
  return json.messages;
}

type ChatSummary = {
  chatId: number;
  summary: string;
};

export async function getChatSummaries(): Promise<ChatSummary[]> {
  let res = await fetch(API_ENDPOINT + "/api/chat/summaries", {
    headers: getHeaders(),
    method: "GET",
  });
  if (!res.ok) {
    throw { error: "Could not get chat summaries" };
  }
  return await res.json();
}

export async function rerunChat(
  chatId: number
): Promise<ResultOrErr<BotResponse>> {
  let res = await fetch(API_ENDPOINT + "/api/chat/" + chatId + "/rerun", {
    headers: getHeaders(),
    method: "POST",
  });
  return await res.json();
}

export type QuestionRecommendation = {
  question: string;
};

export async function getQuestionRecommendations(): Promise<
  QuestionRecommendation[]
> {
  let res = await fetch(API_ENDPOINT + "/api/connectors/recommendations", {
    headers: getHeaders(),
    method: "GET",
  });
  if (!res.ok) {
    throw { error: "Could not get question recommendations" };
  }
  return await res.json();
}

export async function pingDataTeam(
  chatId: number
): Promise<ResultOrErr<number>> {
  let res = await fetch(API_ENDPOINT + "/api/chat/ping" + "?chatId=" + chatId, {
    headers: getHeaders(),
    method: "POST",
  });
  return await res.json();
}
