export const FolderIcon = ({
  size = "24",
  color = "#ffff",
  className,
}: {
  size?: string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.39404 8.14402C3.39404 7.5075 3.6469 6.89705 4.09699 6.44696C4.54707 5.99687 5.15752 5.74402 5.79404 5.74402H11.794L14.194 8.14402H20.194C20.8306 8.14402 21.441 8.39687 21.8911 8.84696C22.3412 9.29705 22.594 9.9075 22.594 10.544V17.744C22.594 18.3805 22.3412 18.991 21.8911 19.4411C21.441 19.8912 20.8306 20.144 20.194 20.144H5.79404C5.15752 20.144 4.54707 19.8912 4.09699 19.4411C3.6469 18.991 3.39404 18.3805 3.39404 17.744V8.14402Z"
        fill={color}
      />
    </svg>
  );
};

export const ArrowCircleRight = ({
  size = "24",
  color = "#BEA32B",
  className,
}: {
  size?: string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4131 18.068C12.5348 18.068 14.5696 17.2251 16.0699 15.7248C17.5702 14.2245 18.4131 12.1897 18.4131 10.068C18.4131 7.94623 17.5702 5.9114 16.0699 4.41111C14.5696 2.91082 12.5348 2.06796 10.4131 2.06796C8.29135 2.06796 6.25652 2.91082 4.75623 4.41111C3.25594 5.9114 2.41309 7.94623 2.41309 10.068C2.41309 12.1897 3.25594 14.2245 4.75623 15.7248C6.25652 17.2251 8.29135 18.068 10.4131 18.068ZM14.1201 9.36096L11.1201 6.36096C10.9315 6.1788 10.6789 6.07801 10.4167 6.08029C10.1545 6.08257 9.90368 6.18774 9.71827 6.37314C9.53286 6.55855 9.42769 6.80936 9.42541 7.07156C9.42313 7.33376 9.52393 7.58636 9.70609 7.77496L10.9991 9.06796H7.41309C7.14787 9.06796 6.89352 9.17332 6.70598 9.36086C6.51844 9.54839 6.41309 9.80275 6.41309 10.068C6.41309 10.3332 6.51844 10.5875 6.70598 10.7751C6.89352 10.9626 7.14787 11.068 7.41309 11.068H10.9991L9.70609 12.361C9.61058 12.4532 9.53439 12.5636 9.48198 12.6856C9.42957 12.8076 9.40199 12.9388 9.40084 13.0716C9.39968 13.2043 9.42498 13.336 9.47526 13.4589C9.52555 13.5818 9.5998 13.6935 9.69369 13.7874C9.78758 13.8813 9.89924 13.9555 10.0221 14.0058C10.145 14.0561 10.2767 14.0814 10.4095 14.0802C10.5423 14.0791 10.6735 14.0515 10.7955 13.9991C10.9175 13.9467 11.0278 13.8705 11.1201 13.775L14.1201 10.775C14.3076 10.5874 14.4129 10.3331 14.4129 10.068C14.4129 9.8028 14.3076 9.54849 14.1201 9.36096Z"
        fill={color}
      />
    </svg>
  );
};

export const DownChevronIcon = ({
  size = "24",
  color = "#BEA32B",
  className,
}: {
  size?: string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99979 5.29307L4.64623 4.93951L1.35329 1.64657C1.35327 1.64655 1.35325 1.64653 1.35323 1.64651C1.25947 1.55281 1.13234 1.50017 0.999786 1.50017C0.867255 1.50017 0.740147 1.55279 0.646393 1.64646C0.646376 1.64648 0.646358 1.64649 0.64634 1.64651C0.646322 1.64653 0.646304 1.64655 0.646287 1.64657C0.552618 1.74032 0.5 1.86743 0.5 1.99996C0.5 2.13252 0.552638 2.25965 0.64634 2.35341L0.292811 2.70693L0.646393 2.35346L4.99979 5.29307ZM4.99979 5.29307L5.35334 4.93951M4.99979 5.29307L5.35334 4.93951M5.35334 4.93951L8.64634 1.64651L8.64639 1.64657M5.35334 4.93951L8.64639 1.64657M8.64639 1.64657L8.65243 1.64032M8.64639 1.64657L8.65243 1.64032M8.65243 1.64032C8.69855 1.59256 8.75373 1.55447 8.81473 1.52826C8.87573 1.50206 8.94134 1.48827 9.00773 1.48769C9.07412 1.48711 9.13996 1.49976 9.20141 1.5249C9.26285 1.55004 9.31868 1.58717 9.36563 1.63412C9.41257 1.68106 9.4497 1.73689 9.47484 1.79834C9.49998 1.85979 9.51263 1.92563 9.51206 1.99202C9.51148 2.05841 9.49769 2.12402 9.47148 2.18502C9.44528 2.24602 9.40719 2.30119 9.35943 2.34731L9.35938 2.34726M8.65243 1.64032L9.35938 2.34726M9.35938 2.34726L9.35323 2.35341M9.35938 2.34726L9.35323 2.35341M9.35323 2.35341L5.35329 6.35335M9.35323 2.35341L5.35329 6.35335M5.35329 6.35335C5.35327 6.35337 5.35325 6.35339 5.35323 6.35341M5.35329 6.35335L5.35323 6.35341M5.35323 6.35341C5.25947 6.44711 5.13234 6.49975 4.99979 6.49975M5.35323 6.35341L4.99979 6.49975M4.99979 6.49975C4.86723 6.49975 4.7401 6.44711 4.64634 6.35341M4.99979 6.49975L4.64634 6.35341M4.64634 6.35341C4.64632 6.35339 4.6463 6.35337 4.64629 6.35335L4.64634 6.35341Z"
        fill="#3579F5"
        stroke="#3579F5"
      />
    </svg>
  );
};

export const RefreshIcon = ({
  size = "24",
  color = "#BEA32B",
  className,
}: {
  size?: string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.9458 10.2501H9.99984Z" fill="#1F2A37" />
      <path
        d="M2.6665 2.91678V6.25011H3.0545M3.0545 6.25011C3.49707 5.15553 4.29029 4.23872 5.30986 3.64336C6.32942 3.04799 7.51771 2.80772 8.68848 2.96021C9.85926 3.11269 10.9464 3.64931 11.7794 4.48597C12.6125 5.32262 13.1444 6.41202 13.2918 7.58345M3.0545 6.25011H5.99984M13.3332 13.5834V10.2501H12.9458M12.9458 10.2501C12.5026 11.3441 11.7092 12.2601 10.6897 12.855C9.67026 13.4498 8.48233 13.6898 7.3119 13.5374C6.14148 13.3849 5.05464 12.8487 4.22153 12.0126C3.38842 11.1765 2.85607 10.0877 2.70784 8.91678M12.9458 10.2501H9.99984"
        stroke="#111928"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const QuestionPlaceholder = ({
  size,
  color,
  className,
}: {
  size?: string;
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      width="265"
      height="39"
      viewBox="0 0 265 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="265" height="15" rx="4" fill="#F6DE73" />
      <rect y="24" width="143" height="15" rx="4" fill="#F6DE73" />
    </svg>
  );
};

export const UnbanxLogo = ({ color = "#ffff" }: { color?: string }) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      data-bbox="0 0 760.31 173.2"
      viewBox="0 0 760.31 173.2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill={color}
          d="m125 57.74 25-14.44L75 0 0 43.3l25 14.44L0 72.17 25 86.6 0 101.04l25 14.43L0 129.9l75.01 43.3L150 129.9l-24.99-14.43L150 101.04l-25-14.43 25-14.44-25-14.43z"
        ></path>
        <path
          d="M286.96 40.64h37.03l33.75 63.6 7.56-4.79c-8.56-13.6-13.73-26.2-13.73-40.17V40.64h26.7v88.16h-31.11l-39.92-75.44-7.68 4.53c7.93 12.22 14.11 28.21 14.11 43.95v26.95h-26.7V40.64Z"
          fill={color}
        ></path>
        <path
          d="M563.77 40.64h37.03l33.75 63.6 7.56-4.79c-8.56-13.6-13.73-26.2-13.73-40.17V40.64h26.7v88.16h-31.11l-39.92-75.44-7.68 4.53c7.93 12.22 14.11 28.21 14.11 43.95v26.95h-26.7V40.64Z"
          fill={color}
        ></path>
        <path
          d="M195.57 41.12h27.78v47.21c0 13.45 4.36 19.43 14.2 19.43s14.08-5.98 14.08-19.43V41.12h28.03v50.45c0 25.91-14.57 38.87-41.98 38.87s-42.11-12.96-42.11-38.87V41.12Z"
          fill={color}
        ></path>
        <path
          d="M386.37 41.12h44.85c19.06 0 29.65 8.35 29.65 21.18 0 10.46-6.48 17.69-18.06 20.31 13.33 2.24 21.43 10.71 21.43 22.05 0 14.82-12.71 23.67-32.39 23.67h-45.47V41.12Zm40.11 34.26c5.48 0 8.97-2.74 8.97-7.22s-3.49-7.22-8.97-7.22h-13.45v14.45h13.45Zm.12 33.01c6.1 0 10.09-2.74 10.09-7.85s-3.99-7.97-10.09-7.97h-13.58v15.82h13.58Z"
          fill={color}
        ></path>
        <path
          d="M524.18 112.37h-30.4l-4.86 15.95h-27.53l29.9-87.2h37.25l30.15 87.2h-29.65l-4.86-15.95Zm-5.85-20.18-2.99-10.21c-1.87-6.35-3.86-13.45-5.98-21.3l-1.74 6.23-4.49 15.32-3.11 9.97h18.31Z"
          fill={color}
        ></path>
        <path
          d="m692.93 82.35-29.4-41.23h32.51l5.85 8.72 8.72 12.46 7.47-11.59 6.23-9.59h32.51l-28.9 41.36 32.39 45.84h-32.76l-8.22-12.46-2.12-3.11-6.73-10.46-9.09 13.7-8.1 12.33h-33.14l32.76-45.97Z"
          fill={color}
        ></path>
      </g>
    </svg>
  );
};
