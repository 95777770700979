import type { ChartConfig } from "../../req";

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { useState } from "react";
import { Bar, Doughnut, Line, PolarArea } from "react-chartjs-2";

export type OutputVals = {
  colNames: Array<string>;
  resultVals: Array<Array<string>>;
};

export type ResultOrErr<T> = { result: T } | { error: string };

export type ChartTypeOptions = "Line" | "Bar" | "Doughnut" | "Arc";

export const ChartComponent = ({
  outputVals,
  chartConfig,
}: {
  outputVals: OutputVals;
  chartConfig: ChartConfig | null;
}) => {
  ChartJS.register(
    Colors,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    RadialLinearScale,
    Title,
    Tooltip,
    Legend
  );
  function transpose<T>(matrix: Array<Array<T>>): Array<Array<T>> {
    if (matrix.length === 0) {
      return [];
    }
    return matrix[0].map((col, i) => matrix.map((row) => row[i]));
  }

  function translateChartType(chartType: string): ChartTypeOptions {
    if (chartType === "bar") {
      return "Bar";
    } else if (chartType === "line") {
      return "Line";
    } else if (chartType === "arc") {
      return "Arc";
    } else if (chartType === "pie") {
      return "Doughnut";
    }
    return "Bar";
  }

  let matrix = transpose(outputVals.resultVals);
  let labelIx = 0;
  let dataIx = 1;
  let secondaryIx = null;

  let chartType_: ChartTypeOptions = "Line";

  if (chartConfig !== null) {
    chartType_ = translateChartType(chartConfig.chartType);
    for (let i = 0; i < outputVals.colNames.length; i++) {
      if (outputVals.colNames[i] === chartConfig.labelAxis) {
        labelIx = i;
      }

      if (outputVals.colNames[i] === chartConfig.dataAxis) {
        dataIx = i;
      }

      if (
        chartConfig.secondaryAxis &&
        outputVals.colNames[i] == chartConfig.secondaryAxis
      ) {
        secondaryIx = i;
      }
    }
  }

  const [chartType, setChartType] = useState<ChartTypeOptions>(chartType_);
  console.log(secondaryIx);
  let secondaryDS =
    secondaryIx !== null && chartType !== "Arc" && chartType !== "Doughnut"
      ? [
          {
            type: "line" as any,
            label: outputVals.colNames[secondaryIx],
            data: matrix[secondaryIx].slice(0, 30).map((x) => +x),
            borderWidth: 1,
            backgroundColor: "rgba(54,162,235)",
            borderColor: "rgba(54,162,235, 0.5)",
          },
        ]
      : [];

  let data = {
    labels: matrix[labelIx].slice(0, 30),
    datasets: [
      ...secondaryDS,
      {
        label: outputVals.colNames[dataIx],
        data: matrix[dataIx].slice(0, 30).map((x) => +x),
        borderWidth: 1,
        backgroundColor:
          chartType === "Bar" || chartType === "Line"
            ? "rgba(255, 99, 132)"
            : undefined,
        borderColor:
          chartType === "Bar" || chartType === "Line"
            ? "rgba(255, 99, 132, 0.6)"
            : undefined,
      },
    ],
  };

  let options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const handleChange = (event: React.MouseEvent<HTMLDivElement>) => {
    const selectedChartType = (event.target as HTMLDivElement).dataset.value;
    if (selectedChartType) {
      setChartType(selectedChartType as ChartTypeOptions);
    }
  };
  return (
    <>
      {" "}
      <div className="chart-container">
        {chartType === "Line" && <Line data={data} options={options} />}
        {chartType === "Bar" && <Bar data={data} options={options} />}
        {chartType === "Doughnut" && <Doughnut data={data} />}
        {chartType === "Arc" && <PolarArea data={data} />}
      </div>
      <div className="chart-selector" onClick={handleChange}>
        <div
          className={`chart-option ${chartType === "Line" ? "selected" : ""}`}
          data-value="Line"
        >
          Line
        </div>
        <div
          className={`chart-option ${chartType === "Bar" ? "selected" : ""}`}
          data-value="Bar"
        >
          Bar
        </div>
        <div
          className={`chart-option ${
            chartType === "Doughnut" ? "selected" : ""
          }`}
          data-value="Doughnut"
        >
          Doughnut
        </div>
        <div
          className={`chart-option ${chartType === "Arc" ? "selected" : ""}`}
          data-value="Arc"
        >
          Arc
        </div>
      </div>
    </>
  );
};
