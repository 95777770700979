import { Table } from "antd";
import { OutputVals } from "../../req";

type TableColumnObject = {
  title: string;
  dataIndex: string;
  key: string;
};

export const DataTable = ({ outputVals }: { outputVals: OutputVals }) => {
  debugger;
  let tableColumns: TableColumnObject[] = [];
  let tableData: any[] = [];

  outputVals.colNames.map((colName, colIndex) => {
    let columnObject = {
      title: colName,
      dataIndex: colName,
      key: colName,
    };
    tableColumns.push(columnObject);
  });

  outputVals.resultVals.map((row, index) => {
    let dataObject: any = {};
    row.map((val, i) => {
      dataObject[tableColumns[i].title] = val;
    });
    tableData.push(dataObject);
  });

  return (
    <div className="bot-message-section data-vis-card table-section">
      <Table
        columns={tableColumns}
        dataSource={tableData}
        pagination={false}
        // scroll={{ y: 500 }}
      />
    </div>
  );
};
